import { serializeTokens } from './tokens'
import {
  PoolCategory,
  SerializedPoolConfig,
} from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.cake,
    contractAddress: {
      97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      56: '0xb58B7AbAd61C6D41D20a8bbD1Df1E223648ac028',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '333',
    sortOrder: 1,
    isFinished: false,
  }
]

export default pools
