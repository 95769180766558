import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
   {
    pid: 0,
    lpSymbol: '$PEPY',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      56: '0xfB44dE508952269195EA564E65b912A858D02d4e',
    },
    token: serializedTokens.syrup,
    quoteToken: serializedTokens.wbnb,
    
  } 
  ,
  {
    pid: 1,
    lpSymbol: '$PEPY-BNB LP',
    lpAddresses: {
      97: '0x3ed8936cAFDF85cfDBa29Fbe5940A5b0524824F4',
      56: '0x79942f04983ac6054Ff4A570656a38fb9f1349fD',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,
    
  }
 

  ,
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
    
  }
  
]

export default farms
